import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.colors.text.textColourTertiary} ${props => props.theme.colors.text.textColourForthColor};
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

body {
    background-color: ${props => props.theme.colors.PrimaryColor};
}
h1{
    color: ${props => props.theme.colors.text.textColourPrimary};
    padding: 20px 0%;
}
h2{
    color: ${props => props.theme.colors.text.textColourPrimary};
}
h3, h4, h5 ,h6{
    color: ${props => props.theme.colors.text.textColourSecoundary};
}
p{
    color: ${props => props.theme.colors.text.textColourSecoundary};
    line-height: 175.2%;
}
a{
    color: ${props => props.theme.colors.text.textColourSecoundary};
}
a:hover{
    color: ${props => props.theme.colors.text.textColourTertiary};
}
strong{
    color: ${props => props.theme.colors.text.textColourPrimary};
}
`

export default GlobalStyle
